<template>
  <div>
    <b-container class="mb-3">
      <b-row>
        <b-col>
          <b-form-select
            text-field="Player Name"
            value-field="Player Name"
            v-model="fromPlayer"
            :options="players"
            :state="fromPlayerState"
            v-show="!isEditFrom"
            v-on:change="onChangeFromPlayer"
          ><b-form-select-option value="edit">edit</b-form-select-option>
          </b-form-select>
          <b-form-input @change.native="onChangeFromEdit" v-model="fromPlayer" v-show="isEditFrom" />
        </b-col>
        <b-col>
          <b-form-select
            text-field="Player Name"
            value-field="Player Name"
            v-model="toPlayer"
            :options="players"
            :state="toPlayerState"
            v-show="!isEditTo"
            v-on:change="onChangeToPlayer"
          ><b-form-select-option value="edit">edit</b-form-select-option></b-form-select>
           <b-form-input @change.native="onChangeToEdit" v-model="toPlayer" v-show="isEditTo" />
        </b-col>
        <b-col>
          <b-button type="button" @click="addData()">
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-table
      fixed
      striped
      selectable
      :items="data"
      :fields="columns"
      :busy="isBusy"
      select-mode="single"
      @row-selected="onRowSelected"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(addition_pts)="row">
        <b-form-input type="number" size="sm" v-model="row.item.addition_pts" />
      </template>
      <template #cell(edit)="row">
        <b-button @click="removePlayer(row.item)" variant="danger" size="sm">
          <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
          Delete
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'MergeUser',
  components: {},
  mounted () {
    this.$store.commit('showSeries', true)
    this.$store.commit('showTournaments', true)
    this.$store.commit('showMatches', false)
    this.$store.commit('refreshTeamRoster', false)
    // console.log('Main mounted')

    this.getPlayer()
    this.getData()
  },
  data () {
    return {
      isBusy: false,
      data: [],
      columns: [
        {
          key: 'name_from',
          label: 'From'
        },
        {
          key: 'name_to',
          label: 'To'
        },
        {
          key: 'edit',
          label: ''
        }
      ],
      players: [],
      fromPlayer: '',
      toPlayer: '',
      fromPlayerState: null,
      toPlayerState: null,
      isEditFrom: false,
      isEditTo: false
    }
  },
  computed: {
    series () {
      return this.$store.state.series
    },
    selectedSeries () {
      return this.$store.state.selectedSeries
    },
    selectedTournamentId () {
      return this.$store.state.selectedTournamentId
    },
    teamRoster () {
      return this.$store.state.teamRoster
    },
    userName () {
      return this.$store.state.userName
    }
  },
  watch: {
    selectedTournamentId () {
      this.getPlayer()
      this.getData()
    }
  },
  methods: {
    onChangeFromEdit () {
      if (this.fromPlayer === '') {
        this.isEditFrom = false
      }
    },
    onChangeToEdit () {
      if (this.toPlayer === '') {
        this.isEditTo = false
      }
    },
    onChangeFromPlayer (arg) {
      if (arg === 'edit') {
        this.isEditFrom = true
      } else {
        this.isEditFrom = false
      }
    },
    onChangeToPlayer (arg) {
      if (arg === 'edit') {
        this.isEditTo = true
      } else {
        this.isEditTo = false
      }
    },
    onRowSelected (items) {
      if (items.length === 0) return

      this.fromPlayer = items[0].name_from
      this.toPlayer = items[0].name_to
    },
    getData () {
      this.isBusy = true

      const url = `/api/pubg/GetPlayerMerge/${this.userName}/${this.selectedTournamentId}`
      axios
        .get(url)
        .then(response => {
          if (response.data.code === 200) {
            this.data.splice(0)
            this.data.push(...response.data.data)

            if (this.data.length === 0) {
              this.$store.commit('toastInfoMsg', `${this.selectedTournamentId}, merge player is empty`)
            }
          } else {
            this.$store.commit('toastErrorMsg', response.data.message)
          }
        })
        .catch(ex => {
          console.warn(`Request Fail: ${url}`, ex)

          if (ex.response) {
            this.$store.commit('toastErrorMsg', ex.response.data.message)
          }
        })
        .then(() => {
          this.isBusy = false
        })
    },
    getPlayer () {
      this.players.splice(0)

      let tmpSeries = this.series.find(x => x.name === this.selectedSeries)
      if (tmpSeries) {
        for (let i = 0; i < this.teamRoster.length; i++) {
          const roster = this.teamRoster[i]
          if (roster.name === tmpSeries.team_roster) {
            this.rosterName = roster.name
            this.players.push(...roster.players)
          }
        }
      }
    },
    removePlayer (data) {
      if (confirm('Delete player?')) {
        this.isBusy = true
        const url = `/api/pubg/DeletePlayerMerge/${this.userName}/${this.selectedTournamentId}/${data.name_from}`
        axios
          .get(url)
          .then(response => {
            if (response.data.code === 200) {
              this.getData()

              this.clearCache()
            } else {
              this.$store.commit('toastErrorMsg', response.data.message)
            }
          })
          .catch(ex => {
            console.warn(`Request Fail: ${url}`, ex)

            if (ex.response) {
              this.$store.commit('toastErrorMsg', ex.response.data.message)
            }
          })
          .then(() => {
            this.isBusy = false
          })
      }
    },
    addData () {
      // valid input
      if (this.fromPlayer === this.toPlayer) {
        this.fromPlayerState = false
        this.toPlayerState = false
      } else {
        this.fromPlayerState = !!this.fromPlayer
        this.toPlayerState = !!this.toPlayer
      }

      if ((this.fromPlayerState && this.toPlayerState) === false) return

      // get player
      let tmpPlayer = this.players.find(x => x.player_name === this.toPlayer)
      if (tmpPlayer === undefined) {
        tmpPlayer = {
          player_name: this.toPlayer,
          player_id: 99}
      }
      // request
      const url = `/api/pubg/SetPlayerMerge`
      const param = {
        tournament_id: this.selectedTournamentId,
        name_from: this.fromPlayer,
        name_to: this.toPlayer,
        id_to: tmpPlayer.player_id,
        account_id: this.userName
      }

      // valid param
      const player = this.data.find(
        x => x.name_from === this.fromPlayer && x.name_to === this.toPlayer
      )
      if (player) {
        this.$store.commit('toastErrorMsg', 'data already exist')
        return
      }

      this.isBusy = true

      axios
        .post(url, param)
        .then(response => {
          if (response.data.code === 200) {
            this.getData()

            this.clearCache()
          } else {
            this.$store.commit('toastErrorMsg', response.data.message)
          }
        })
        .catch(ex => {
          console.warn(`Request Fail: ${url}`, ex)

          if (ex.response) {
            this.$store.commit('toastErrorMsg', ex.response.data.message)
          }
        })
        .then(() => {
          this.isBusy = false
          this.fromPlayerState = null
          this.toPlayerState = null
        })
    },
    clearCache () {
      axios.get(`api/pubg/clearcache?name=leaderboard`)
    }
  }
}
</script>
<style scoped>
</style>
