<template>
  <div>
    <b-container fluid class="mb-3">
      <b-row>
        <b-col>
          <b-button class="float-right" @click="saveData">
            <b-icon
              class="mr-2"
              icon="cloud-upload"
              aria-hidden="true"
            ></b-icon>
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <b-form>
      <b-form-group
        id="input-group-1"
        label="Linked tournament Id:"
        label-for="id-input"
      >
        <b-form-input
          id="id-input"
          v-model="linkedId"
          type="text"
          placeholder="Id"
          :state="linkedIdState"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-2"
        label="From date:"
        label-for="from-date-input"
      >
        <b-input-group>
          <b-form-input
            id="from-date-input"
            v-model="fromDate"
            type="text"
            placeholder="YYYY-MM-DD"
            autocomplete="off"
            :state="fromDateState"
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="fromDate"
              button-only
              right
              locale="en-US"
              aria-controls="from-date-input"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
        id="input-group-3"
        label="To date:"
        label-for="to-date-input"
      >
        <b-input-group>
          <b-form-input
            id="to-date-input"
            v-model="toDate"
            type="text"
            placeholder="YYYY-MM-DD"
            autocomplete="off"
            :state="toDateState"
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              v-model="toDate"
              :min="fromDate"
              button-only
              right
              locale="en-US"
              aria-controls="to-date-input"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>
    <b-button
      block
      type="button"
      variant="danger"
      @click="removeData()"
      :disabled="disabled"
    >
      <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
      Delete
    </b-button>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CustomTouenaments',
  components: {},
  mounted () {
    this.$store.commit('showSeries', true)
    this.$store.commit('showTournaments', true)
    this.$store.commit('showMatches', false)
    this.$store.commit('refreshTeamRoster', false)
    this.getData()
  },
  data () {
    return {
      linkedId: '',
      fromDate: '',
      toDate: '',
      linkedIdState: null,
      fromDateState: null,
      toDateState: null,
      disabled: true
    }
  },
  computed: {
    series () {
      return this.$store.state.series
    },
    selectedSeries () {
      return this.$store.state.selectedSeries
    },
    selectedTournamentId () {
      return this.$store.state.selectedTournamentId
    },
    teamRoster () {
      return this.$store.state.teamRoster
    },
    userName () {
      return this.$store.state.userName
    }
  },
  watch: {
    selectedTournamentId () {
      this.getData()
    }
  },
  methods: {
    onRowSelected (items) {
      if (items.length === 0) return

      this.fromPlayer = items[0].name_from
      this.toPlayer = items[0].name_to
    },
    getData () {
      const url = `/api/pubg/cnitournament/${this.userName}/${this.selectedTournamentId}`
      axios
        .get(url)
        .then(response => {
          if (response.data.code === 200) {
            const data = response.data.data
            if (data) {
              this.linkedId = data.linked_tournament_id
              this.fromDate = data.date_from.substring(0, 10)
              this.toDate = data.date_to.substring(0, 10)
              this.disabled = false
            } else {
              this.linkedId = ''
              this.fromDate = ''
              this.toDate = ''
              this.disabled = true

              this.$store.commit(
                'toastInfoMsg',
                `${this.selectedTournamentId} is not custom tournament`
              )
            }
          } else {
            this.$store.commit('toastErrorMsg', response.data.message)
          }
        })
        .catch(ex => {
          console.warn(`Request Fail: ${url}`, ex)

          if (ex.response) {
            this.$store.commit('toastErrorMsg', ex.response.data.message)
          }
        })
    },
    removeData () {
      if (confirm('Delete custom tournament?')) {
        const url = `/api/pubg/DeleteCustomTournament/${this.userName}/${this.selectedTournamentId}`
        axios
          .get(url)
          .then(response => {
            if (response.data.code === 200) {
              this.getData()
            } else {
              this.$store.commit('toastErrorMsg', response.data.message)
            }
          })
          .catch(ex => {
            console.warn(`Request Fail: ${url}`, ex)

            if (ex.response) {
              this.$store.commit('toastErrorMsg', ex.response.data.message)
            }
          })
      }
    },
    saveData () {
      // valid input
      this.linkedIdState = !!this.linkedId
      this.fromDateState = !!this.fromDate
      this.toDateState = !!this.toDate

      if (
        (this.linkedIdState && this.fromDateState && this.toDateState) === false
      ) {
        return
      }

      if (this.toDate <= this.fromDate) {
        this.fromDateState = false
        this.toDateState = false
        return
      }

      // request
      const url = `/api/pubg/cnitournament`
      const param = {
        id: this.selectedTournamentId,
        linked_tournament_id: this.linkedId,
        date_from: `${this.fromDate} 00:00:00`,
        date_to: `${this.toDate} 00:00:00`,
        account_id: `${this.userName}`
      }

      axios
        .post(url, param)
        .then(response => {
          if (response.data.code === 200) {
            this.getData()
          } else {
            this.$store.commit('toastErrorMsg', response.data.message)
          }
        })
        .catch(ex => {
          console.warn(`Request Fail: ${url}`, ex)

          if (ex.response) {
            this.$store.commit('toastErrorMsg', ex.response.data.message)
          }
        })
        .then(() => {
          this.linkedIdState = null
          this.fromDateState = null
          this.toDateState = null
        })
    }
  }
}
</script>
<style scoped></style>
