<template>
  <div style="height:100%;">
    <div class="side">
      <div class="logo-wrap">
        <router-link to="/">
        <img src="../../assets/logo.png" class="logo" />
        </router-link>
      </div>
      <Aside class="sidebar-menu" />
    </div>
    <Header class="HeaderFragment" />
    <div class="content-wrap vld-parent" style="height:100%;">
      <div class="content">
          <slot  />
      </div>
    </div>
  </div>
</template>
<script>
import Header from './fragment/header.vue'
import Aside from './fragment/aside.vue'

export default {
  name: 'LayoutPage',
  components: {
    Header,
    Aside
  }
}
</script>
<style scoped>
.main-wrap {
  display: grid;
  height: 100%;
}

.content-wrap {
  display: grid;
  padding-top:40px;
  margin-left: 220px;
}

.logo-wrap {
  background-color: #111;
}

.logo {
  /* width: 90px; */
  height: 40px;
}

.side {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 220px;
  background-color: #343a40;
  text-align: center;
}

.HeaderFragment {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  padding-left: 220px;
  z-index: 10;
}

.content {
  padding: 20px;
}
.footer {
  background-color: white;
  height: 100px;
  margin-top: auto;
}
</style>
