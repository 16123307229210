<template>
  <div>
    <loading :active.sync="isLoading" :is-full-page="false"></loading>
    <b-container fluid class="mb-3">
      <b-row>
        <b-col>
          SERIES <b-form-select
            text-field="name"
            value-field="name"
            v-model="exSeries"
            :options="series" />
        TOURNAMENT <b-form-select
            v-model="exTournamentId"
            :options="exTournaments" />
          </b-col>
        <b-col>
          <b-button @click="updateData" class="float-right">
            <b-icon class="mr-2" icon="cloud-upload" aria-hidden="true"></b-icon>
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col class="pl-0">
          <b-table :items="matchIds" :fields="columnsMatch" striped>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(other_tournament_matches)="row">
              {{ row.item }}
            </template>
            <template #cell(edit)="row">
              <b-button @click="IncludeMatch(row.item)" size="sm">
                Include
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col class="pr-0">
          <b-table
            :items="externalMatchIds"
            :fields="columnsExternalMatch"
            striped
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(external_matches)="row">
              {{ row.item }}
            </template>

            <template #cell(edit)="row">
              <b-button @click="deleteExternalMatch(row.item)" size="sm">
                Remove
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading
  },
  mounted () {
    this.$store.commit('showSeries', true)
    this.$store.commit('showTournaments', true)
    this.$store.commit('showMatches', false)
    this.$store.commit('refreshTeamRoster', false)
    if (this.series.length > 0) {
      this.exSeries = this.series[0].name
    }
    this.getExternalMatches(this.selectedTournamentId)
  },
  data () {
    return {
      columnsMatch: [
        {
          key: 'other_tournament_matches'
        },
        {
          key: 'edit',
          label: ''
        }
      ],
      columnsExternalMatch: [
        {
          key: 'external_matches'
        },
        {
          key: 'edit',
          label: ''
        }
      ],
      // tournament_id: '',
      matchIds: [],
      externalMatchIds: [],
      exTournamentId: '',
      waitMatch: false,
      waitExternalMatch: false,
      waitUpdate: false,
      exTournaments: [],
      exSeries: ''
    }
  },
  computed: {
    isLoading () {
      return this.waitMatch || this.waitExternalMatch || this.waitUpdate
    },
    matches () {
      return this.$store.state.matches
    },
    selectedSeries () {
      return this.$store.state.selectedSeries
    },
    selectedTournamentId () {
      return this.$store.state.selectedTournamentId
    },
    externalTournamentId () {
      return this.exTournamentId
    },
    userName () {
      return this.$store.state.userName
    },
    series () {
      return this.$store.state.series
    }
  },
  watch: {
    selectedTournamentId () {
      this.getExternalMatches(this.selectedTournamentId)
    },
    exSeries () {
      this.getTournaments()
    },
    externalTournamentId () {
      this.getMatches(this.externalTournamentId)
    }
    // matches (newVal, oldVal) {
    //   this.waitMatch = false

    //   this.matchIds.splice(0)
    //   this.matchIds.push(...newVal)
    // }
  },

  methods: {
    getTournaments () {
      const curSeries = this.series.find(x => x.name === this.exSeries)
      if (
        !curSeries ||
        !Array.isArray(curSeries.tournaments) ||
        !curSeries.tournaments.length
      ) {
        this.matchIds.splice(0)
        return
      }
      // this.exTournaments.splice(0)
      // for(const aId in curSeries.tournaments){
      //   this.exTournaments.push({
      //     text:aId,
      //     value:aId
      //   })
      // }
      this.exTournaments = curSeries.tournaments
      // console.log(this.exTournaments)
      this.exTournamentId = this.exTournaments[0]
    },
    getMatchList () {
      // this.waitMatch = true
      // this.matchIds.splice(0)
      // this.$store.dispatch('getMatchList', id)
    },
    getMatches (tournamentId) {
      this.waitMatch = true
      this.matchIds.splice(0)
      const api = `api/pubg/matchlist/${this.userName}/${tournamentId}`
      axios
        .get(api)
        .then(response => {
          if (response.data.data != null) {
            this.matchIds.push(...response.data.data)
          }
        })
        .catch(ex => {
          console.warn('ERROR!!!!! : ', ex)
        })
        .then(() => {
          this.waitMatch = false
        })
    },
    getExternalMatches (id) {
      this.waitExternalMatch = true
      this.externalMatchIds.splice(0)

      const url = `api/pubg/GetAddMatch/${this.userName}/${id}`
      axios
        .get(url)
        .then(response => {
          if (response.data.data != null) {
            this.externalMatchIds.push(...response.data.data)
          }
        })
        .catch(ex => {
          console.warn('ERROR!!!!! : ', ex)
        })
        .then(() => {
          this.waitExternalMatch = false
        })
    },
    IncludeMatch (matchId) {
      this.externalMatchIds.push(matchId)

      const idx = this.matchIds.indexOf(matchId)
      this.matchIds.splice(idx, 1)
    },
    deleteExternalMatch (matchId) {
      // this.matchIds.push(matchId)
      const idx = this.externalMatchIds.indexOf(matchId)
      this.externalMatchIds.splice(idx, 1)
    },
    updateData () {
      if (!confirm('Update Data?')) return

      this.waitUpdate = true
      const url = '/api/pubg/SetAddMatch'

      const param = {
        account_id: this.userName,
        tournament_id: this.selectedTournamentId,
        match_ids: this.externalMatchIds
      }

      // console.log('setIgnoreMatch')
      // console.log(param)

      axios.post(url, param, {
        headers: {
          'x-token': 'mbccni##pubg',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.status === 200) {
          this.getMatches(this.externalTournamentId)
        }
      }).catch(ex => {
        console.warn('ERROR!!!!! : ', ex)
      }).then(() => {
        this.waitUpdate = false
      })
    }
  }
}
</script>
<style scoped>
</style>
