<template lang="html">
<div>
<form id="signupForm" @submit.prevent="sendPost">
    <input type="text" v-model='userId' placeholder='e-mail address'/>
    <input type="password" v-model='password'/>
    <button>Sign up</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      userId: '',
      password: '',
      password2: '',
      checkpwd: ''
    }
  },
  methods: {
    checkPassword () {
      if (this.password != this.password2) {
        this.checkpwd = ''
      }
    },
    getMarkdown () {
      axios.get('livestats_api_list.md').then(response => {
        this.mdText = response.data
      })
    },
    sendPost () {
      console.log(this.userId + ':::' + this.password)
      // this.goToPages()
      let addUserData = {
        user_id: this.userId,
        user_pw: this.password
      }
      console.log(addUserData)
      axios.post('/api/pubg/AddAccount', addUserData, {
        headers: {
          'x-token': 'mbccni##pubg',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        console.log(response)
      }).catch((ex) => {
        console.warn('ERROR!!!!! : ', ex)
      })
    }
  }
}
</script>
