<template>
  <div class="wrapHeader">
    <b-nav v-if="isSigned">
      <!-- 시리즈 -->
      <b-nav-item-dropdown
        :text="selectedSeries"
        v-show="showSeries"
        class="category-dropdown"
      >
        <b-dropdown-item
          v-for="item in series"
          v-bind:key="item.name"
          @click="onClickSeries(item.name)"
        >
          {{ item.name }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item v-show="refreshTeamRoster" class="reload-btn" @click="onClickRefreshTeamRoster">
        <b-icon icon="arrow-repeat" aria-hidden="true"/>
      </b-nav-item>
      <!-- 토너먼트 -->
      <b-nav-item-dropdown
        :text="selectedTournamentId"
        v-show="showTournaments"
        class="category-dropdown"
      >
        <b-dropdown-item
          v-for="item in tournaments"
          v-bind:key="item"
          @click="onClickTournament(item)"
        >
          {{ item }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <!-- 매치 -->
      <b-nav-item-dropdown
        :text="selectedMatch"
        v-show="showMatches"
        class="match-dropdown"
      >
        <b-dropdown-item
          v-for="(item, index) in matches"
          v-bind:key="item"
          @click="onClickMatch(item)"
        >
          [{{ add0(matches.length - index) }}] {{ item }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item v-show="showMatches" class="reload-btn" @click="onClickReloadMatch">
        <!-- <b-button variant="link" > -->
          <b-icon icon="arrow-repeat" aria-hidden="true"></b-icon>
        <!-- </b-button> -->
      </b-nav-item>
    </b-nav>
    <!-- 로그인 -->
    <b-navbar-nav class="ml-auto mr-2">
      <Sign />
    </b-navbar-nav>
  </div>
</template>
<script>
// import Constant from '@/constant'
import Sign from '@/components/Sign.vue'

export default {
  name: 'HeaderFragment',
  components: { Sign },
  data: function () {
    return {
      // tournaments: []
    }
  },
  computed: {
    isSigned () {
      return this.$store.state.isSigned
    },
    currentRouteName () {
      if (this.$route.name) {
        return this.$route.name
      } else {
        return 'PUBG'
      }
    },
    series () {
      return this.$store.state.series
    },
    tournaments () {
      return this.$store.state.tournaments
    },
    matches () {
      return this.$store.state.matches
    },
    selectedSeries: {
      get: function () {
        return this.$store.state.selectedSeries
      },
      set: function (newValue) {
        this.$store.dispatch('setSelectedSeries', newValue)
        let newSeries = this.series.find(x => x.name === this.selectedSeries)
        this.$store.dispatch('selectedSeriesType', newSeries.type)
      }
    },
    selectedTournamentId: {
      get: function () {
        return this.$store.state.selectedTournamentId
      },
      set: function (newValue) {
        this.$store.dispatch('setSelectedTournamentId', newValue)
      }
    },
    selectedMatch: {
      get: function () {
        return this.$store.state.selectedMatch
      },
      set: function (newValue) {
        this.$store.commit('selectedMatch', newValue)
      }
    },
    showSeries () {
      return this.$store.state.showSeries
    },
    refreshTeamRoster () {
      return this.$store.state.refreshTeamRoster
    },
    showTournaments () {
      return this.$store.state.showTournaments
    },
    showMatches () {
      return this.$store.state.showMatches
    }
  },
  methods: {
    onClickSeries (val) {
      this.selectedSeries = val
    },
    onClickTournament (val) {
      this.selectedTournamentId = val
    },
    onClickMatch (val) {
      this.selectedMatch = val
    },
    onClickReloadMatch () {
      this.$store.dispatch('getMatchList', this.selectedTournamentId)
    },
    onClickRefreshTeamRoster () {
      this.$store.dispatch('getTeamRoster')
    },
    add0 (val) {
      const maxDigits = 2
      var length = maxDigits - val.toString().length
      if (length <= 0) {
        return val
      }

      var leadingZeros = new Array(length + 1)
      return leadingZeros.join('0') + val.toString()
    }
  }
}
</script>
<style>
</style>
<style scoped>
ul {
  height: 100%;
}

ul li {
  margin: auto 0px;
  text-align: center;
}

ul li.category-dropdown {
  margin: 11px 0px;
  height: 1.2rem;
  width: 180px;
  border-right: solid 1px #d9e0e7;
}

ul li.category-dropdown >>> a {
  padding-top: 0.1rem;
}

ul li.match-dropdown {
  margin: 11px 0px;
  height: 1.2rem;
  width: 315px;
}

ul li.match-dropdown >>> a {
  padding-top: 0.1rem;
}

ul li >>> .dropdown-menu {
  margin-top: 0px;
  border-radius: 0px;
  width: 100%;
}

ul li >>> span {
  display: inline-block;
  width: 90%;
}

.reload-btn {
  z-index: 1;
}

.reload-btn a {
  padding: 0px;
  margin-left: 0.5rem;
}

.cni-dropdown {
  border-radius: 15px;
  background-color: #348fe2;
  margin: 10px;
}

.wrapHeader {
  background-color: rgb(255, 0, 0);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
}
.bg-gradient-success {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important;
}

</style>
