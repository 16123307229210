<template>
    <div class="img" v-bind:style="{ backgroundImage: 'url(' + mainImgName + ')' }">
    </div>
    <!-- <img :src="mainImgName" class="img"/> -->
</template>
<script>
export default {
  name: 'MainPage',
  mounted () {
    this.$store.commit('showSeries', false)
    this.$store.commit('showTournaments', false)
    this.$store.commit('showMatches', false)
    this.$store.commit('refreshTeamRoster', false)
    console.log('Main mounted')
  },
  data () {
    return {
      mainImgName: require('../assets/guide_' + Math.floor(Math.random() * 10) % 3 + '.jpg')
    }
  },
  components: {}
}
</script>
<style scoped>
.img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: cover;
  background-position: center 0px;
  /* margin-top:-40px; */
}
</style>
