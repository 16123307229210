<template>
  <div>
    <loading :active.sync="isLoading" :is-full-page="false"></loading>
    <b-container fluid class="mb-3">
      <b-row>
        <b-col>
          <b-button @click="updateData" class="float-right">
            <b-icon class="mr-2" icon="cloud-upload" aria-hidden="true"></b-icon>
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col class="pl-0">
          <b-table :items="matchIds" :fields="columnsMatch" striped>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(current_matches)="row">
              {{ row.item }}
            </template>
            <template #cell(edit)="row">
              <b-button @click="addIgnoreMatch(row.item)" size="sm">
                Ignore
              </b-button>
            </template>
          </b-table>
        </b-col>
        <b-col class="pr-0">
          <b-table
            :items="ignoreMatchIds"
            :fields="columnsIgnoredMatch"
            striped
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(ignored_matches)="row">
              {{ row.item }}
            </template>

            <template #cell(edit)="row">
              <b-button @click="deleteIgnoreMatch(row.item)" size="sm">
                Remove
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading
  },
  mounted () {
    this.$store.commit('showSeries', true)
    this.$store.commit('showTournaments', true)
    this.$store.commit('showMatches', false)
    this.$store.commit('refreshTeamRoster', false)

    this.getData()
  },
  data () {
    return {
      columnsMatch: [
        {
          key: 'current_matches'
        },
        {
          key: 'edit',
          label: ''
        }
      ],
      columnsIgnoredMatch: [
        {
          key: 'ignored_matches'
        },
        {
          key: 'edit',
          label: ''
        }
      ],
      // tournament_id: '',
      matchIds: [],
      ignoreMatchIds: [],
      waitMatch: false,
      waitIgnoreMatch: false,
      waitUpdate: false
    }
  },
  computed: {
    isLoading () {
      return this.waitMatch || this.waitIgnoreMatch || this.waitUpdate
    },
    matches () {
      return this.$store.state.matches
    },
    selectedSeries () {
      return this.$store.state.selectedSeries
    },
    selectedTournamentId () {
      return this.$store.state.selectedTournamentId
    },
    userName () {
      return this.$store.state.userName
    }
  },
  watch: {
    selectedTournamentId () {
      this.getData()
    },
    matches (newVal) {
      this.waitMatch = false

      this.matchIds.splice(0)
      this.matchIds.push(...newVal)
    }
  },

  methods: {
    getData () {
      this.getMatchList(this.selectedTournamentId)
      this.getIgnorematch(this.selectedTournamentId)
    },
    getMatchList (id) {
      this.waitMatch = true
      this.matchIds.splice(0)

      this.$store.dispatch('getMatchList', id)
    },
    getIgnorematch (id) {
      this.waitIgnoreMatch = true
      this.ignoreMatchIds.splice(0)

      const url = `/api/pubg/getignorematch/${this.userName}/${id}`
      axios
        .get(url)
        .then(response => {
          if (response.data.data != null) {
            this.ignoreMatchIds.push(...response.data.data.match_ids)
          }
        })
        .catch(ex => {
          console.warn('ERROR!!!!! : ', ex)
        })
        .then(() => {
          this.waitIgnoreMatch = false
        })
    },
    addIgnoreMatch (matchId) {
      this.ignoreMatchIds.push(matchId)

      const idx = this.matchIds.indexOf(matchId)
      this.matchIds.splice(idx, 1)
    },
    deleteIgnoreMatch (matchId) {
      this.matchIds.push(matchId)

      const idx = this.ignoreMatchIds.indexOf(matchId)
      this.ignoreMatchIds.splice(idx, 1)
    },
    updateData () {
      if (!confirm('Update Data?')) return

      this.waitUpdate = true
      const url = '/api/pubg/setignorematch'
      const param = {
        account_id: this.userName,
        tournament_id: this.selectedTournamentId,
        match_ids: this.ignoreMatchIds
      }
      // console.log('setIgnoreMatch')
      // console.log(param)
      axios
        .post(url, param)
        .then(response => {
          if (response.status === 200) {
            this.getMatchList(this.selectedTournamentId)
          }
        })
        .catch(ex => {
          console.warn('ERROR!!!!! : ', ex)
        })
        .then(() => {
          this.waitUpdate = false
        })
    }
  }
}
</script>
<style scoped>
/* .tableContent {
  margin: 5px;
}
body {
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
  color: #444;
}

table {
  border: 1px solid black;
  border-radius: 3px;
  background-color: #fff;
}

th {
  background-color: #f9f9f9;
  border: 1px solid black;
  color: black;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

td {
  border: 1px #646262;
  background-color: #ffffff;
}

th,
td {
  min-width: 120px;
  padding: 10px 20px;
}

th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
} */
</style>
