/* eslint-disable */ // eslint-disable-next-line
<template>
  <div>
    <loading :active.sync="isLoading" :is-full-page="false"></loading>
    <b-container fluid class="mb-3">
      <b-row>
        <b-col>
          <b-button v-b-modal.pwd-modal class="float-right">
            <b-icon
              class="mr-2"
              icon="cloud-upload"
              aria-hidden="true"
            ></b-icon>
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <div>
      <fieldset class="form-group">
        <legend tabindex="-1" class="bv-no-focus-ring col-form-label pt-0">
          Series <b-button size="sm" v-b-modal.series-modal>add</b-button>
        </legend>
        <div>
          <b-form-select
            v-model="selectedSeries"
            text-field="name"
            value-field="name"
            :options="series"
          ></b-form-select>
        </div>
      </fieldset>
      <b-form-group label="Roster">
        <b-form-select
          v-model="selectedTeamRoster"
          :options="teamRoster"
        ></b-form-select>
      </b-form-group>
      <b-form-group label="Tournaments">
        <b-form-textarea @blur="updateTournament" v-model="tournaments">
        </b-form-textarea>
      </b-form-group>
    </div>
    <!-- 추가 -->
    <b-modal
      hide-footer
      id="series-modal"
      ref="series-modal"
      title="Add Series"
      @show="resetModal"
    >
      <b-form-input
        id="name-input"
        v-model="addSeriesName"
        :state="addSeriesNameState"
        invalid-feedback="Name is required"
        required
      ></b-form-input>
      <b-button class="mt-3" variant="outline-dark" block @click="addSeries">
        Add
      </b-button>
    </b-modal>
    <b-modal
      hide-footer
      id="pwd-modal"
      ref="pwd-modal"
      title="Need Password"
      invalid-feedback="Password is required"
      @show="resetModal"
    >
      <b-form-input
        id="name-input"
        v-model="pwd"
        :state="pwdState"
        type="password"
        @keyup.enter="updateData"
      ></b-form-input>
      <b-button class="mt-3" variant="outline-dark" block @click="updateData">
        Save
      </b-button>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
// import { relativeTimeRounding } from 'moment'

export default {
  components: {
    Loading
  },
  mounted () {
    this.$store.commit('showSeries', false)
    this.$store.commit('showTournaments', false)
    this.$store.commit('showMatches', false)
    this.$store.commit('refreshTeamRoster', false)
    // this.tourIds = this.$store.state.selectedTournamentId
    this.series = JSON.parse(JSON.stringify(this.$store.state.series))
    this.selectedSeries = this.$store.state.selectedSeries

    this.teamRoster.push({
      value: null,
      text: 'None'
    })

    const teamRoster = [...this.$store.state.teamRoster]
    for (let i = 0; i < teamRoster.length; i++) {
      const item = teamRoster[i]
      this.teamRoster.push({
        value: item.name,
        text: item.name
      })
    }

    // tmpTeamRoster.push(...JSON.parse(JSON.stringify(this.$store.state.teamRoster)))
    // this.teamRoster = tmpTeamRoster

    // console.log('this.teamRoster', this.teamRoster)
  },
  data () {
    return {
      id: '',
      pwd: '',
      pwdState: null,
      series: [],
      teamRoster: [],
      selectedSeries: '',
      selectedTeamRoster: '',
      tournaments: '',
      addSeriesName: '',
      addSeriesNameState: null,
      isLoading: false
    }
  },
  computed: {},
  watch: {
    selectedSeries (newVal) {
      const curSeries = this.series.find(x => x.name === newVal)

      // console.log(curSeries)
      if (curSeries) {
        this.selectedTeamRoster = curSeries.team_roster
        this.tournaments = curSeries.tournaments.join('\n')
      }
    },
    selectedTeamRoster (newVal) {
      const curSeries = this.series.find(x => x.name === this.selectedSeries)

      if (curSeries) {
        curSeries.team_roster = newVal
      }
    }
  },
  methods: {
    resetModal () {
      this.addSeriesName = ''
      this.addSeriesNameState = null
      this.pwd = ''
      this.pwdState = null
    },
    updateData () {
      if (!this.pwd) {
        this.pwdState = false
        return
      }

      this.$refs['pwd-modal'].hide()

      this.isLoading = true

      const useName = this.$store.state.userName
      const role = this.$store.state.role
      const url = '/api/pubg/series'
      const param = {
        user_id: useName,
        user_pw: this.pwd,
        role: role,
        series: JSON.parse(JSON.stringify(this.series))
      }

      // console.log(param)
      axios
        .post(url, param)
        .then( () => {
          this.$store.dispatch('login', { id: useName, pw: this.pwd })
        })
        .catch(ex => {
          console.warn('ERROR!!!!! : ', ex)
        })
        .then(() => {
          this.isLoading = false
        })
    },
    addSeries () {
      if (!this.addSeriesName) {
        this.addSeriesNameState = false
        return
      }

      const tmpSeries = this.series.find(x => x.name === this.addSeriesName)
      if (!tmpSeries) {
        this.series.push({
          name: this.addSeriesName,
          team_roster: this.teamRoster[0].name,
          tournaments: []
        })

        this.$refs['series-modal'].hide()
      } else {
        this.$bvToast.toast(`${this.addSeriesName} is already exist`, {
          title: 'Error',
          autoHideDelay: 5000
        })
      }
    },
    changeTeam (event, selectedIndex) {
      this.aSeriesTourIds.team_roster = this.teamSeries[selectedIndex].name
    },
    updateTournament () {
      const curSeries = this.series.find(x => x.name === this.selectedSeries)

      const set = new Set(
        this.tournaments
          .split('\n')
          .map(x => x.trim())
          .filter(x => !!x)
      )
      curSeries.tournaments = [...set]

      this.tournaments = curSeries.tournaments.join('\n')
    }
    // getTeamRoster () {
    //   var api = '/api/pubg/teamRoster/'
    //   console.log('get TeamRoster: ' + api)
    //   axios
    //     .get(api, { headers: { 'Content-Type': 'application/json' } })
    //     .then(response => {
    //       this.teamSeries = response.data.data
    //     })
    //     .catch(ex => {
    //       console.warn('ERROR!!!!! : ', ex)
    //     })
    // }
  }
}
</script>
<style scoped>
.form-group >>> div textarea {
  height: 300px;
}
</style>
