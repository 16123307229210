<template>
  <div class="login-wrap">
    <b-nav-item-dropdown right class="cni-sign-dropdown">
      <!-- Using 'button-content' slot -->
      <template #button-content>
        <div class="user-icon mr-3">
          <b-icon-person-fill />
        </div>
        <span v-if="isSigned">{{ userName }}</span>
        <span v-if="!isSigned">Sign</span>
      </template>
      <b-dropdown-item v-b-modal.signin-modal v-show="!isSigned">
        Sign in
      </b-dropdown-item>
      <b-dropdown-item @click="signOut" v-show="isSigned">
        Sign Out
      </b-dropdown-item>
      <b-dropdown-item v-b-modal.signup-modal v-show="!isSigned">
        Sign Up
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <!-- 로그인 -->
    <b-modal
      hide-footer
      id="signin-modal"
      ref="signin-modal"
      title="Sign In"
      @show="resetModal"
    >
      <b-form-group
        label="Username"
        label-for="name-input"
        invalid-feedback="Name is required"
        :state="nameState"
      >
        <b-form-input
          id="name-input"
          type="text"
          v-model="tmpName"
          :state="nameState"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Password"
        label-for="pwd-input"
        invalid-feedback="Password is required"
        :state="pwdState"
      >
        <b-form-input
          id="pwd-input"
          v-model="tmpPwd"
          :state="pwdState"
          type="password"
          @keyup.enter="signIn"
          required
        ></b-form-input>
      </b-form-group>
      <b-button class="mt-3" variant="outline-dark" block @click="signIn">
        OK
      </b-button>
    </b-modal>
    <!-- 회원가입 -->
    <b-modal
      hide-footer
      id="signup-modal"
      ref="signup-modal"
      title="Sign Up"
      @show="resetModal"
    >
      <b-form-group
        label="Username"
        label-for="name-input"
        invalid-feedback="Name is required"
        :state="nameState"
      >
        <b-form-input
          id="name-input"
          v-model="tmpName"
          :state="nameState"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Password"
        label-for="pwd-input"
        invalid-feedback="Password is required"
        :state="pwdState"
      >
        <b-form-input
          id="pwd-input"
          v-model="tmpPwd"
          :state="pwdState"
          type="password"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Confirmation Password"
        label-for="cpwd-input"
        invalid-feedback="confirmation password do not match"
        :state="cpwdState"
      >
        <b-form-input
          id="cpwd-input"
          v-model="tmpCPwd"
          :state="cpwdState"
          type="password"
          @keyup.enter="signUp"
          required
        ></b-form-input>
      </b-form-group>
      <b-button class="mt-3" variant="outline-dark" block @click="signUp">
        Save
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LoginPage',
  data () {
    return {
      changedData: {},
      tmpName: '',
      tmpPwd: '',
      tmpCPwd: '',
      nameState: null,
      pwdState: null,
      cpwdState: null
    }
  },
  computed: {
    userName () {
      return this.$store.state.userName
    },
    isSigned () {
      return this.$store.state.isSigned
    }
  },
  watch: {
    userName (newVal) {
      if (newVal === this.tmpName) {
        this.$refs['signin-modal'].hide()
      }
    }
  },
  mounted () {},
  methods: {
    checkSignInValidity () {
      this.nameState = !!this.tmpName
      this.pwdState = !!this.tmpPwd

      return this.nameState && this.pwdState
    },
    checkSignUpValidity () {
      this.nameState = !!this.tmpName
      this.pwdState = !!this.tmpPwd
      this.cpwdState = (this.tmpPwd === this.tmpCPwd)

      return this.nameState && this.pwdState && this.cpwdState
    },
    clickOk: function () {
      this.visible = false
      this.$emit('ok', this.username, this.pwd)
    },
    resetModal () {
      this.tmpName = ''
      this.nameState = null
      this.tmpPwd = ''
      this.pwdState = null
      this.tmpCPwd = ''
      this.cpwdState = null
    },
    signIn () {
      if (this.checkSignInValidity()) {
        this.$store.dispatch('login', { id: this.tmpName, pw: this.tmpPwd })
      }
    },
    signOut () {
      this.$router.push('/')
      this.$store.dispatch('logout')
    },
    signUp () {
      if (!this.checkSignUpValidity()) return

      const url = '/api/pubg/AddAccount'
      const param = {
        user_id: this.tmpName,
        user_pw: this.tmpPwd,
        role: null
      }
      console.log(url, param)
      axios
        .post(url, param)
        .then(response => {
          if (response.data.code === 200) {
            this.$store.commit('toastInfoMsg', 'Signup Success')
            this.$refs['signup-modal'].hide()
          } else {
            this.$store.commit('toastErrorMsg', response.data.message)
          }
        })
        .catch(ex => {
          console.warn(`Request Fail: ${url}`, ex)

          if (ex.response) {
            this.$store.commit('toastErrorMsg', ex.response.data.message)
          }
        })
    }
  }
}
</script>

<style scoped>
.login-wrap {
  display: flex;
  align-items: center;
  height: 100%;
}

.user-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 1rem;
}
.cni-sign-dropdown {
  text-align: center;
  min-width: 100px;
  /* border-radius: 15px;
  background-color: #00acac;
  margin: 10px; */
}
</style>
