/* eslint-disable */ // eslint-disable-next-line
<template>
  <div>
    <b-container class="mb-3">
      <b-row>
        <b-col>
          <b-form-select
            v-model="teamName"
            :options="teamNames"
            :state="teamNameState"
            v-show="!isEdit"
            v-on:change="onChangeTeam"
          ><b-form-select-option value="edit">edit</b-form-select-option>
          </b-form-select>
          <b-form-input @change.native="onChangeEdit" v-model="teamName" v-show="isEdit" />
        </b-col>
        <b-col>
          <b-form-input
            type="number"
            v-model="penalty"
            placeholder="penalty"
            :state="penaltyState"
          />
        </b-col>
        <b-col>
          <b-button type="button" @click="onClickSave()">
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <b-table
      fixed
      striped
      selectable
      :items="points"
      :fields="columns"
      :busy="isBusy"
      select-mode="single"
      @row-selected="onRowSelected"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <!-- <template #cell(addition_pts)="row">
        <b-form-input type="number" size="sm" v-model="row.item.addition_pts" />
      </template> -->
      <!-- <template #cell(edit)="row">
        <b-button
          @click="btnUpdatePenalty(row.item.team_name, row.item.addition_pts)"
          size="sm"
        >
          Update
        </b-button>
      </template> -->
      <template #cell(edit)="row">
        <b-button @click="removePenalty(row.item)" variant="danger" size="sm">
          <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
          Delete
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import Constant from '@/constant'

import axios from 'axios'
export default {
  mounted () {
    this.$store.commit('showSeries', true)
    this.$store.commit('showTournaments', true)
    this.$store.commit('showMatches', false)
    this.$store.commit('refreshTeamRoster', false)
    this.getTeamNames()
    this.getPoints()
  },
  data () {
    return {
      isBusy: false,
      id: '',
      teamName: '',
      penalty: 0,
      points: [],
      teamNames: [],
      teamNameState: null,
      penaltyState: null,
      columns: Constant.PENALTY_COLUMN,
      isEdit: false
      // columns: ['tournament_id', 'teamName', 'penalty', '']
    }
  },
  computed: {
    selectedTournamentId () {
      return this.$store.state.selectedTournamentId
    },
    series () {
      return this.$store.state.series
    },
    selectedSeries () {
      return this.$store.state.selectedSeries
    },
    teamRoster () {
      return this.$store.state.teamRoster
    },
    userName () {
      return this.$store.state.userName
    }
  },
  watch: {
    selectedTournamentId () {
      this.getPoints()
    }
  },
  methods: {
    onChangeEdit () {
      if (this.teamName === '') {
        this.isEdit = false
      }
    },
    onChangeTeam (arg) {
      if (arg === 'edit') {
        this.isEdit = true
      } else {
        this.isEdit = false
      }
    },
    onRowSelected (items) {
      if (items.length === 0) return
      this.teamName = items[0].team_name
      this.penalty = items[0].addition_pts
    },
    removePenalty (data) {
      if (confirm('Delete penalty?')) {
        this.isBusy = true
        const url = `/api/pubg/DeleteAdditionPts/${this.userName}/${this.selectedTournamentId}/${data.team_name}`
        axios
          .get(url)
          .then(response => {
            if (response.data.code === 200) {
              this.getPoints()
            } else {
              this.$store.commit('toastErrorMsg', response.data.message)
            }
          })
          .catch(ex => {
            console.warn(`Request Fail: ${url}`, ex)

            if (ex.response) {
              this.$store.commit('toastErrorMsg', ex.response.data.message)
            }
          })
          .then(() => {
            this.isBusy = false
          })
      }
    },
    getPoints () {
      this.isBusy = true

      this.teamName = ''
      this.penalty = 0
      this.teamNameState = null
      this.penaltyState = null

      const url = `/api/pubg/GetAdditionPts/${this.userName}/${this.selectedTournamentId}`
      axios
        .get(url)
        .then(response => {
          this.points.splice(0)
          this.points.push(...response.data.data)

          if (this.points.length === 0) {
            this.$store.commit('toastInfoMsg', `${this.selectedTournamentId}, Penalty is empty`)
          }
        })
        .catch(ex => {
          console.warn('ERROR!!!!! : ', ex)
        })
        .then(() => {
          this.isBusy = false
        })
    },
    getTeamNames () {
      this.teamNames.splice(0)
      var tmpSeries = this.series.find(x => x.name === this.selectedSeries)
      if (tmpSeries) {
        for (let i = 0; i < this.teamRoster.length; i++) {
          const roster = this.teamRoster[i]
          if (roster.name === tmpSeries.team_roster) {
            var tmpTeams = []
            // 이름만 가져오기
            // tmpTeams.push(...roster.players.map(x => x.team_name))
            tmpTeams.push(...roster.players.map(x => x['Team Name']))

            // 중복 제거
            const set = new Set(tmpTeams)

            tmpTeams = [...set]
            // tmpTeams = tmpTeams.map(x => {
            //   const item = {
            //     value: x,
            //     text: x
            //   }
            //   return item
            // })

            this.teamNames.push(...tmpTeams)
          }
        }
      }
    },
    btnUpdatePenalty (teamName, penalty) {
      this.setAdditionPts(teamName, penalty)
    },
    onClickSave () {
      this.teamNameState = !!this.teamName
      this.penaltyState = !!`${this.penalty}`.length

      if (this.teamNameState && this.penaltyState) {
        this.setAdditionPts(this.teamName, this.penalty)
      }
    },
    setAdditionPts (teamName, penalty) {
      this.isBusy = true
      // const url = `/api/pubg/SetAdditionPts/${this.userName}/${this.selectedTournamentId}?team_name=${teamName}&add_pts=${penalty}`
      const url = `/api/pubg/SetAdditionPts/${this.userName}/${this.selectedTournamentId}/${teamName}/${penalty}`
      axios
        .get(url)
        .then( () => {
          this.isBusy = false

          this.getPoints()
        })
        .catch(ex => {
          console.warn('ERROR!!!!! : ', ex)
        })
        .then(() => {
          this.isBusy = false
        })
    }
  }
}
</script>
<style scoped></style>
