/* eslint-disable */
// eslint-disable-next-line
<template>
  <div class="tableContent">
    <b-button>
      <b-icon class="mr-2" icon="cloud-download" aria-hidden="true"></b-icon>
      <!-- <a href="http://gofile.me/5hARR/vbttxqWo1" target="_blank">DOWN LOAD</a> -->
      <a href="https://home.dreamatum.synology.me/sharing/6b22UDclm" target="_blank">DOWN LOAD1</a>
    </b-button>
    <br>
    <br>
    <b-button>
      <b-icon class="mr-2" icon="cloud-download" aria-hidden="true"></b-icon>
      <a href="https://home.esperatum.synology.me/sharing/WMFlfWKY7" target="_blank">DOWN LOAD2</a>
    </b-button>
  </div>
</template>

<script>
export default {
  mounted () {
    this.$store.commit('showSeries', false)
    this.$store.commit('showTournaments', false)
    this.$store.commit('showMatches', false)
    this.$store.commit('refreshTeamRoster', false)
  },
  data () {
    return {
      columnsDownload: ['date', 'file', 'event'],
      fileList: []
    }
  }
}
</script>
<style scoped>
</style>
