export default {
  TOURNAMENT_ALL: 'ALL',
  TEAM_STAT_COLUMNS: [
    {
      key: 'rank',
      label: 'Rank',
      type: 'number',
      sortable: true
    },
    {
      key: 'match_lastest_rank',
      label: 'Match Lastest Rank',
      type: 'number',
      sortable: true
    },
    {
      key: 'lastest_total_point',
      label: 'Lastest Total Point',
      type: 'number',
      sortable: true
    },
    {
      key: 'team',
      label: 'Team',
      sortable: true
    },
    {
      key: 'team_tag',
      label: 'Team Tag',
      sortable: true
    },
    {
      key: 'match',
      label: 'Match',
      type: 'number',
      sortable: true
    },
    {
      key: 'total_pts',
      label: 'Total Pts',
      type: 'number',
      sortable: true
    },
    {
      key: 'place_pts',
      label: 'Place Point',
      type: 'number',
      sortable: true
    },
    {
      key: 'total_kills',
      label: 'Total Kills',
      type: 'number',
      sortable: true
    },
    {
      key: 'avg_kill',
      label: 'AVG Kill',
      type: 'number',
      sortable: true
    },
    {
      key: 'total_dmg',
      label: 'Total DMG',
      type: 'number',
      sortable: true
    },
    {
      key: 'latest_Dmg',
      label: 'Latest DMG',
      type: 'number',
      sortable: true
    },
    {
      key: 'avg_dmg',
      label: 'AVG DMG',
      type: 'number',
      sortable: true
    },
    {
      key: 'match_best_pts',
      label: 'Match Best Pts ',
      type: 'number',
      sortable: true
    },
    {
      key: 'match_highest_kills',
      label: 'Match Highest Kills',
      type: 'number',
      sortable: true
    },
    {
      key: 'match_lastest_kills',
      label: 'Match Lastest Kills',
      type: 'number',
      sortable: true
    },
    {
      key: 'grenade',
      label: 'Grenade',
      type: 'number',
      sortable: true
    },
    {
      key: 'smoke',
      label: 'Smoke',
      type: 'number',
      sortable: true
    },
    {
      key: 'molotov',
      label: 'Molotov',
      type: 'number',
      sortable: true
    },
    {
      key: 'stun',
      label: 'Stun',
      type: 'number',
      sortable: true
    },
    {
      key: 'total_death',
      label: 'Total Death',
      type: 'number',
      sortable: true
    },
    {
      key: 'kill_steal',
      label: 'Kill Steal',
      type: 'number',
      sortable: true
    },
    {
      key: 'wwcd',
      label: 'WWCD',
      type: 'number',
      sortable: true
    },
    {
      key: 'addition_pts',
      label: 'Addition pts',
      type: 'number',
      sortable: true
    },
    {
      key: 'total_time',
      label: 'Total time',
      sortable: true
    },
    {
      key: 'avg_time',
      label: 'AVG time',
      sortable: true
    }
  ],
  PLAYER_STAT_COLUMNS: [
    {
      key: 'Player_ID',
      label: 'Player ID'
    },
    {
      key: 'Match',
      label: 'Match',
      type: 'number'
    },
    {
      key: 'TotalKill',
      label: 'Total Kill',
      type: 'number'
    },
    {
      key: 'AVG_Kill',
      label: 'AVG Kill',
      type: 'number'
    },
    {
      key: 'HeadShot_kill',
      label: 'HeadShot Kill',
      type: 'number'
    },
    {
      key: 'HeadShot_kill_finish',
      label: 'HeadShot Kill Finish',
      type: 'number'
    },
    {
      key: 'Total_Assist',
      label: 'Total Assist',
      type: 'number'
    },
    {
      key: 'Total_Damage',
      label: 'Total DMG',
      type: 'number'
    },
    {
      key: 'Head_Dmg',
      label: 'Head DMG',
      type: 'number'
    },
    {
      key: 'Arm_Dmg',
      label: 'Arm DMG',
      type: 'number'
    },
    {
      key: 'Torso_Dmg',
      label: 'Torso DMG',
      type: 'number'
    },
    {
      key: 'Pelvis_Dmg',
      label: 'Pelvis DMG',
      type: 'number'
    },
    {
      key: 'Leg_Dmg',
      label: 'Leg DMG',
      type: 'number'
    },
    {
      key: 'Non_Specific_Dmg',
      label: 'Non Specific DMG',
      type: 'number'
    },
    {
      key: 'Avg_Damage',
      label: 'Avg DMG',
      type: 'number'
    },
    {
      key: 'Grenade_Used',
      label: 'Grenade',
      type: 'number'
    },
    {
      key: 'Smoke_Used',
      label: 'Smoke',
      type: 'number'
    },
    {
      key: 'Stun_Used',
      label: 'Stun',
      type: 'number'
    },
    {
      key: 'Molotov_Used',
      label: 'Molotov',
      type: 'number'
    },
    {
      key: 'Total_Knocked',
      label: 'Total Knocked',
      type: 'number'
    },
    {
      key: 'Total_Knocks',
      label: 'Total Knocks',
      type: 'number'
    },
    {
      key: 'Total_Revives',
      label: 'Total Revives',
      type: 'number'
    },
    {
      key: 'Total_Revived',
      label: 'Total Revived',
      type: 'number'
    },
    {
      key: 'Total_SurvivalTime',
      label: 'Total Survival Time'
    },
    {
      key: 'AVG_SurvivalTime',
      label: 'AVG Survival Time'
    },
    {
      key: 'carry_time_sum',
      label: 'Total Carry Time',
      type: 'number'
    },
    {
      key: 'carry_distance_sum',
      label: 'Total Carry Dist.',
      type: 'number'
    },
    {
      key: 'carried_time_sum',
      label: 'Total Carried Time',
      type: 'number'
    },
    {
      key: 'carried_distance_sum',
      label: 'Total Carried Dist.',
      type: 'number'
    },
    {
      key: 'Total_Distance',
      label: 'Total Distance',
      type: 'number'
    },
    {
      key: 'Longest_Kill',
      label: 'Longest Kill',
      type: 'number'
    }
  ],
  PLAYER_COLUMN: [
    {
      key: 'team_id',
      label: 'Team Id'
    },
    {
      key: 'team_name',
      label: 'Team Name'
    },
    {
      key: 'team_tag',
      label: 'Team Tag'
    },
    {
      key: 'player_id',
      label: 'Player Id'
    },
    {
      key: 'player_name',
      label: 'Player Name'
    },
    {
      key: 'player_mapping',
      label: 'Player Mapping'
    },
    {
      key: 'edit',
      label: ''
    }
  ],
  PENALTY_COLUMN: [
    // {
    //   key: 'tournament_id',
    //   label: 'Tournament Id'
    // },
    {
      key: 'team_name',
      label: 'Team Name'
    },
    {
      key: 'addition_pts',
      label: 'Pts'
    },
    {
      key: 'edit',
      label: ''
    }
  ],
  KILLLOG_COLUMNS: [
    'index',
    {
      key: 'time_str',
      label: 'time_str',
      sortable: true
    },
    {
      key: 'Killer',
      label: 'Killer',
      sortable: true
    },
    {
      key: 'Victim',
      label: 'Victim',
      sortable: true
    }
  ]
}
