import Vue from 'vue'
import Router from 'vue-router'
import TeamUpdate from '@/components/TeamUpdate'
import Subscribe from '@/components/SubscribeTournament'
import SignUp from '@/components/SignUp'
import UserMain from '@/components/MainPage'
import Statistics from '@/components/GameStatistics'
import AdditionalPoint from '@/components/AdditionalPoint'
import LiveMatch from '@/components/LiveMatch'
import IgnoreMatch from '@/components/IgnoreMatch'
import downloadPage from '@/components/DownloadPage'
import CustomTournaments from '@/components/CustomTournaments'
import MergeUser from '@/components/MergeUser'
import manageMatch from '@/components/manageMatch'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    // {
    //   path: '/',
    //   name: 'LoginPage',
    //   component: LoginPage
    // },
    {
      path: '/',
      component: UserMain
    },
    {
      path: '/teamUpdate',
      name: 'teamUpdate',
      component: TeamUpdate
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: Subscribe
    },
    {
      path: '/SignUp',
      name: 'SignUp',
      component: SignUp
    },
    {
      path: '/UserMain',
      name: 'UserMain',
      component: UserMain
    },
    {
      path: '/Statistics',
      name: 'Statistics',
      component: Statistics
    },
    {
      path: '/livematch',
      name: 'LiveMatch',
      component: LiveMatch
    },
    {
      path: '/AdditionalPoint',
      name: 'AdditionalPoint',
      component: AdditionalPoint
    },
    {
      path: '/IgnoreMatch',
      name: 'IgnoreMatch',
      component: IgnoreMatch
    },
    {
      path: '/downloadPage',
      name: 'downloadPage',
      component: downloadPage
    },
    {
      path: '/customtournaments',
      name: 'CustomTournaments',
      component: CustomTournaments
    },
    {
      path: '/merge',
      name: 'MergePlayer',
      component: MergeUser
    },
    {
      path: '/manageMatch',
      name: 'ManageMatch',
      component: manageMatch
    }
  ]
})
